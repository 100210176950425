import React, { Component } from 'react'
import { Typography, Grid, createStyles } from '@material-ui/core'
import Image from 'gatsby-image'
import SVG, { Props as SVGProps } from 'react-inlinesvg'

import LazyLoad from 'react-lazyload'

import target from './target.png'
import SlideAnimation from '../../../components/SlideAnimation'
import {
  ContentContainer,
  getContainerStyles,
} from '../../../components/ContentContainer'
import { createSizesForBreakpoints } from '../../../utils/sizes'

const targetIconSize = '0.9375rem'

export const getStyles = (theme) => {
  const containerStyles = getContainerStyles(theme)
  const flexBreakpointUp = theme.breakpoints.up('md')
  return {
    subtitle: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1.0625rem',
    },
    subtitleIcon: {
      width: targetIconSize,
      height: targetIconSize,
      marginRight: '0.1875rem',
    },
    subtitleText: {
      textTransform: 'uppercase',
      fontSize: '11px',
      fontWeight: 700,
      lineHeight: '0.9075rem',
      letterSpacing: '0.03em',
      marginBottom: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: '0.6875rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '0.6875rem',
      },
    },
    body: {
      // marginBottom: '2rem',
    },
    title: {
      marginBottom: '18px',
      fontSize: '18px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '2.625rem',
        fontSize: '24px',
        // width: '413px',
      },
      [theme.breakpoints.up('lg')]: {
      },
    },
    image: {
      maxWidth: '100%',
      width: '100%',
    },
    imageColumn: {
      width: '100%',
      [theme.breakpoints.up('xs')]: {
        // display: 'flex',
        order: 1,
        position: 'relative',
        marginBottom: '-90px',
        // justifyContent: 'center',
      },
      [theme.breakpoints.up('sm')]: {
        order: 2,
        width: '23rem',
        textAlign: 'center',
        marginBottom: '0px',
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: '0px',
        width: '23rem',
      },
    },
    textContainer: {
      [theme.breakpoints.up('xs')]: {
        order: 2,
      },
    },
    text: {
      [theme.breakpoints.up('xs')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '240px',
        fontSize: '1.375rem',
      },
    },
    container: {
      ...containerStyles.container,
      padding: 0,
    },
  }
}

export const createSectionStyles = ({ sectionMarginTop }) => (
  theme
) => {
  const sectionStyles = getStyles(theme)
  return createStyles({
    ...sectionStyles,
    section: {
      ...sectionStyles.section,
      marginTop: sectionMarginTop,
      display: 'flex',
      alignItems: 'flex-start',
    },
    body: {
      ...sectionStyles.body,
      // ...sizesForBreakpoints,
    },
    svgAnimation: {
      width: '200px',
      [theme.breakpoints.up('sm')]: {
        width: '310px'
      },
      [theme.breakpoints.up('lg')]: {
        width: '310px'
      },
    },
    textContainer: {
      [theme.breakpoints.up('xs')]: {
        padding: '30px',
        paddingTop: '50px',
        width: '150px',
        order: 2,
      },
      [theme.breakpoints.up('sm')]: {
        padding: '30px',
        width: '260px',
        order: 1,
        position: 'relative',
        top: '35px',
      },
      backgroundColor: '#101010',
      padding: '30px',
      borderRadius: '10px',
    }
  })
}

class Section extends Component {
  render() {
    const {
      classes,
      subtitle,
      title,
      children,
      image,
      imageMd,
      alt,
      imageDirection,
    } = this.props

    const direction = imageDirection === 'left' ? 'row-reverse' : 'row'

    return (
      <ContentContainer classes={{ container: classes.container }}>
        <Grid
          container
          className={classes.section}
          direction={direction}
          alignItems="flex-start"
          justify="center"
        >
          <div className={classes.textContainer} style={{
            right: imageDirection === 'left' ? '30px' : '',
          }}>
            <div className={classes.body}>
              <Typography className={classes.title} variant="h2">
                {title}
              </Typography>
              <Typography className={classes.text}>{children}</Typography>
            </div>
          </div>
          <div className={classes.imageColumn} style={{
            textAlign: imageDirection === 'left' ? '' : 'right',
            right: imageDirection === 'left' ? '' : '50px',
            left: imageDirection === 'left' ? '' : 'auto',
          }}>
            <img className={classes.svgAnimation} src={image} />
          </div>
        </Grid>
      </ContentContainer>
    )
  }
}

export default Section
