import React from 'react'
import { withStyles } from '@material-ui/core'
import { useStaticQuery, graphql } from 'gatsby'

import Section, { createSectionStyles } from './Section'

import addAccounts from './addAccounts.png'

const styles = createSectionStyles({
  // sectionMarginTop: '7.4375rem',
  bodyWidth: {
    md: '19.625rem',
    lg: '27.5rem',
  },
})

const ConnectYourAccounts = ({ classes }) => {
  const { image, imageMd } = useStaticQuery(graphql`
    {
      image: file(name: { eq: "refinance" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMd: file(
        name: { eq: "refinance" }
        absolutePath: { regex: "/md/" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Section
      classes={classes}
      title="Connect Your Accounts"
      image={addAccounts}
      imageMd={addAccounts}
      imageDirection="right"
    >
      Connect your financial accounts so everything is automated.
    </Section>
  )
}

export default withStyles(styles)(ConnectYourAccounts)
