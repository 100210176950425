import React, { Component } from 'react'
import { Typography, Container } from '@material-ui/core'
import Image from 'gatsby-image'

import SlideAnimation from '../components/SlideAnimation'
import { getContainerStyles } from '../components/ContentContainer'

export const flexBreakpoint = 'md'

export const getStyles = (theme) => {
  const flexBreakpointUp = theme.breakpoints.up(flexBreakpoint)
  const containerStyles = getContainerStyles(theme)
  return {
    image: {
      marginBottom: '1.8081rem',
      maxWidth: '100%',
      width: '100%',
    },
    imageRight: {
      order: 2,
    },
    body: {
      padding: '0 2rem',
      marginTop: '1.8081rem',
      [flexBreakpointUp]: {
        padding: 0,
        marginTop: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        order: 1,
      },
    },
    title: {
      marginBottom: '2rem',
      fontSize: '1.75rem',
      fontWeight: 700,
      [theme.breakpoints.up('md')]: {
        fontSize: '2.25rem',
      },
    },
    section: {
      [flexBreakpointUp]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    container: {
      ...containerStyles.container,
      padding: 0,
      overflowX: 'hidden',
    },
    text: {
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.375rem',
      },
    },
  }
}

class ImageSection extends Component {
  getClasses = () => {
    const { imageDirection = 'left', classes } = this.props
    if (imageDirection === 'right') {
      return `${classes.image} ${classes.imageRight}`
    }
    return classes.image
  }

  getImageFluid = () => {
    const { image, imageMd } = this.props
    const imagesWereLoaded = image && imageMd
    return imagesWereLoaded
      ? [
          image.childImageSharp.fluid,
          {
            ...imageMd.childImageSharp.fluid,
            media: '(min-width: 960px)',
          },
        ]
      : null
  }

  render() {
    const { classes, alt, title, children, imageDirection } = this.props
    const fluid = this.getImageFluid()
    return (
      <Container className={classes.container}>
        <SlideAnimation direction={imageDirection}>
          <section className={classes.section}>
            {fluid && (
              <Image fluid={fluid} alt={alt} className={this.getClasses()} />
            )}
            <div className={classes.body}>
              <Typography variant="h2" className={classes.title}>
                {title}
              </Typography>
              <Typography className={classes.text}>{children}</Typography>
            </div>
          </section>
        </SlideAnimation>
      </Container>
    )
  }
}

export default ImageSection
