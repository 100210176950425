import React, { useState, useEffect } from 'react'
import { Button, createStyles, Typography, Hidden, withStyles } from '@material-ui/core'

import { Link } from 'gatsby'

import ComingSoon from './ComingSoon'
import Video from './Video'
import Contact from './Contact'
import { getHeroStyles } from '../../styles/hero'
import heroImage from './device.png'
import shadow from './shadow.png'
import appStoreButton from '../../images/availableOnApple.png'

const styles = (theme) => {
  const heroStyles = getHeroStyles()
  return createStyles({
    container: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      },
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
      },
    },
    section: {
      ...heroStyles,
      marginBottom: '3.4881rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginBottom: '75px',
        alignItems: 'flex-start',
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: '75px',
        alignItems: 'flex-start',
      },
    },
    title: {
      marginBottom: '2.5rem',
      fontSize: '30px',
      lineHeight: '1.34',
      fontWeight: 800,
      maxWidth: '330px',
      // padding: "0 15px",

      [theme.breakpoints.up('md')]: {
        maxWidth: '420px',
        fontSize: '38px',
        textAlign: 'left',
        marginTop: '4rem',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '560px',
        fontSize: '46px',
        textAlign: 'left'
      },
    },
    subtitle: {
      color: '#F0EDEF',
      fontSize: '18px',
      [theme.breakpoints.up('sm')]: {
        width: '520px',
      },
      [theme.breakpoints.up('md')]: {
        width: '520px',
        fontSize: '1.25rem',
        textAlign: 'left',
      },
      [theme.breakpoints.up('lg')]: {
        textAlign: 'left',
        width: '570px',
      },
    },
    heroImage_wrpper: {
      marginTop: "20px",
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        position: 'relative',
      },
      [theme.breakpoints.up('lg')]: {
        position: 'relative',
        top: '90px',
      },

    },
    heroImage: {
      transform: 'rotate(10deg)',
      maxWidth: '80%',
      [theme.breakpoints.up('md')]: {
        width: '350px',
        maxWidth: '45rem',
        position: 'relative',
        left: '50px',
      },
      [theme.breakpoints.up('lg')]: {
        width: '500px',
        left: 'auto',
        right: '60px',
      },
    },
    link: {
      textDecoration: 'none',
    },
    linkTwo: {
      textDecoration: 'none',
      position: 'relative',
      top: '-65px',
      left: '190px',
    },
    hashtag: {
      color: '#E1E1E1',
      fontFamily: 'Montserrat',
      fontWeight: '400',
      marginTop: '0',
      marginBottom: '2.5625rem',
      fontSize: '13px',
      [theme.breakpoints.up('md')]: {
        fontSize: '26px',
      },
    },
    mobileOnly: {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    appButton: {
      width: '150px',
      display: 'block',
      marginTop: '20px',
    },
    appButtonTwo: {
      width: '140px',
    }
  })
}

const Hero = ({ classes }) => {

  const [isPhone, setIsPhone] = useState(false)

  useEffect(() => {
    setIsPhone(window.innerWidth <= 599)
    window.addEventListener("resize", handleResize);
  }, [])

  const handleResize = (e) => {
    setIsPhone(window.innerWidth <= 599)
  }

  return (
    <div className={classes.container}>
      <section className={classes.section}>
        <Typography align="center" variant="h1" className={classes.title}>

          Smartest Way
          <Hidden smUp>
            <br />
          </Hidden> to Manage <Hidden smUp>
            <br />
          </Hidden> Your Finances
          {/* Personal Finance <span style={{ fontWeight: '500' }}>for</span> Forward Thinkers */}
        </Typography>
        <Typography
          align="center"
          variant="subtitle1"
          className={classes.subtitle}
        >
          Avocado Finance makes it easy to manage all of your money, see where your net worth is tracking toward, and know if you are on pace to achieve your financial goals.
          {/* Avocado Finance makes it easy to understand your finances today, see what your net worth tracking toward, and know if you are on pace to achieve your goals and financial freedom. */}
          {/* Make informed money decisions {<br className={classes.mobileOnly} />} with Avocado Finance. */}
        </Typography>
        <Video />
        <Hidden smUp>
          <Link to={isPhone ? 'https://avocadofinance-alternate.app.link' : '/get-app'} className={classes.link}>
            <img className={classes.appButton} src={appStoreButton} />
          </Link>
        </Hidden>
        <Hidden smUp>
          <a href="https://www.producthunt.com/posts/avocado-finance?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-avocado&#0045;finance" target="_blank">
            <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=336488&theme=dark" alt="Avocado&#0032;Finance - Smartest&#0032;way&#0032;to&#0032;manage&#0032;your&#0032;finances | Product Hunt" style={{ width: '150px', height: '54px' }} />
          </a>
        </Hidden>
        <Hidden smDown>
          <Link to={isPhone ? 'https://avocadofinance-alternate.app.link' : '/get-app'} className={classes.linkTwo}>
            <img className={classes.appButtonTwo} src={appStoreButton} />
          </Link>
        </Hidden>
        {/* <Hidden smDown>
          <Contact />
        </Hidden> */}
        {/* <ComingSoon /> */}
      </section>
      <div className={classes.heroImage_wrpper}>
        <img
          src={heroImage}
          className={classes.heroImage}
          alt='Images of three pages. One has the "Today" page , another has the "Forecast" page, and the last one has an "Insights" page.'
        />
      </div>
    </div>
  )
}

export default withStyles(styles)(Hero)
