import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection, { getStyles, flexBreakpoint } from './ImageSection'

const styles = (theme) => {
  const imageSectionStyles = getStyles(theme)
  const flexBreakpointUp = theme.breakpoints.up(flexBreakpoint)
  return createStyles({
    ...imageSectionStyles,
    section: {
      ...imageSectionStyles.section,
      marginTop: '9.8125rem',
    },
    image: {
      ...imageSectionStyles.image,
      [flexBreakpointUp]: {
        width: '25rem',
      },
      [theme.breakpoints.up('lg')]: {
        width: '37.5rem',
      },
    },
    body: {
      ...imageSectionStyles.body,
      [flexBreakpointUp]: {
        ...imageSectionStyles.body[flexBreakpointUp],
        width: '18.6875rem',
      },
      [theme.breakpoints.up('lg')]: {
        width: '27.5rem',
      },
    },
  })
}

const YourFinances = ({ classes }) => {
  const { image, imageMd } = useStaticQuery(graphql`
    {
      image: file(name: { eq: "your-finances" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMd: file(
        name: { eq: "your-finances" }
        absolutePath: { regex: "/md/" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <ImageSection
      classes={classes}
      title="Your Finances, in the Future"
      alt="Four graphs, where each graph is a line chart and goes from 'Today' to 'Future'. The first graph is named 'Cash,' is ascending, and has a label of $15,000. The second line is named 'Investments,' is ascending, and has a label of $20,000. Third, there is a graph named 'Debt' that has a label of $10,000 and is descending. Finally, there is a graph named 'Net Worth' that is ascending and has a label of $25,000."
      image={image}
      imageMd={imageMd}
      imageDirection="right"
    >
      With a simple and intuitive dashboard, understanding your future financial
      picture is now in the palm of your hands.
    </ImageSection>
  )
}

export default withStyles(styles)(YourFinances)
