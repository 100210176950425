import React, { useEffect } from 'react'
import { createStyles, Typography, Hidden, withStyles } from '@material-ui/core'

import videoPlaceholder from '../images/videoPlaceholder.png'

const styles = (theme) => {
  return createStyles({
    container: {
      width: '80%',
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        width: '60%',
      },
    },
    videoPlaceholder: {
      display: 'block',
      margin: '0 auto',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '800px',
      },
    }
  })
}

const Wistia = ({ classes }) => {
  useEffect(() => {
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    script1.src = "https://fast.wistia.com/embed/medias/ueuowzzpxr.jsonp";
    script1.async = true;

    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }, [])

  return (
    <div className={classes.container}>
      <div class="wistia_responsive_padding" style={{ padding: '56.67% 0 0 0', position: 'relative' }}>
        <div class="wistia_responsive_wrapper" style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%' }}>
          <span
            class="wistia_embed wistia_async_ueuowzzpxr popover=true popoverAnimateThumbnail=true"
            style={{
              display: 'inline-block',
              height: '84px',
              position: 'relative',
              width: '150px'
            }}>
            &nbsp;
          </span>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(Wistia)
