import React, { Component } from 'react'
import {
  Container,
  createStyles,
  withStyles,
  Typography,
  Hidden,
} from '@material-ui/core'

import ScrollAnimation from 'react-animate-on-scroll'

import reviews from './reviews.png'
import reviewsStar from './reviewsStar.png'

import reviewOne from './priscillayip.png'
import reviewTwo from './claytonbigsby.png'
import reviewThree from './dexterlee.png'
import reviewFour from './iwantacookie.png'
import reviewFive from './keedon.png'
import reviewSix from './cky.png'


import { flexBreakpoint } from './styles'

const styles = (theme) =>
  createStyles({
    section: {
      marginTop: '190px',
      marginBottom: '280px',
      // [theme.breakpoints.up(flexBreakpoint)]: {
      //   marginTop: '280px',
      // },
      [theme.breakpoints.up('xs')]: {
        marginTop: '100px',
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: '0px',
        marginTop: '140px',
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: '280px',
      },
    },
    container: {
      padding: '0 1.125rem',
    },
    title: {
      color: '#F0EDEF',
      marginBottom: '7.375rem',
      fontWeight: 800,
      lineHeight: '1.32',
      [theme.breakpoints.up('xs')]: {
        marginBottom: '45px',
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: '5.375rem',
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: '7.375rem',
      },
    },
    list: {
      display: 'flex',
      padding: '0 0.5rem',
      flexDirection: 'column',
      [theme.breakpoints.up(flexBreakpoint)]: {
        flexDirection: 'row',
        padding: '0 1.5rem',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '0 2.9375rem',
      },
    },
    reviewsImage: {
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        width: '102%',
        left: '-17px',
      }
    },
    reviewsStar: {
      [theme.breakpoints.up('xs')]: {
        width: '77px',
        height: '31px',
        marginRight: '8px',
      },
      [theme.breakpoints.up('md')]: {
        width: '158px',
        height: '64px',
        marginRight: '20px',
      }
    },
    reviewsText: {
      fontFamily: 'Montserrat',
      color: '#ffffff',
      fontWeight: 'SemiBold',

      [theme.breakpoints.up('xs')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '32px',
      }
    },
    reviewsIndividual: {
      width: '100%',
      marginBottom: '20px',
    },
    starContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    mobileReviewsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '25px',
    }
  })

class Testimonials extends Component {
  render() {
    const { classes } = this.props
    return (
      <section className={classes.section}>
        <Container maxWidth={false} className={classes.container}>
          <ScrollAnimation animateIn="fadeIn" animateOnce>
            <Typography variant="h3" className={classes.title} align="center">
              What People are Saying About Avocado Finance
            </Typography>
            <div>
              <Hidden mdUp>
                <div className={classes.starContainer}>
                  <img src={reviewsStar} className={classes.reviewsStar} alt="App Store Reviews Star Score" />
                  <p className={classes.reviewsText}>ON THE APP STORE</p>
                </div>
                <div className={classes.mobileReviewsContainer}>
                  <img src={reviewOne} className={classes.reviewsIndividual} alt="User review" />
                  <img src={reviewTwo} className={classes.reviewsIndividual} alt="User review" />
                  <img src={reviewThree} className={classes.reviewsIndividual} alt="User review" />
                  <img src={reviewFour} className={classes.reviewsIndividual} alt="User review" />
                  <img src={reviewFive} className={classes.reviewsIndividual} alt="User review" />
                  <img src={reviewSix} className={classes.reviewsIndividual} alt="User review" />
                </div>
              </Hidden>

              <Hidden smDown>
                <div className={classes.starContainer}>
                  <img src={reviewsStar} className={classes.reviewsStar} alt="App Store Reviews Star Score" />
                  <p className={classes.reviewsText}>ON THE APP STORE</p>
                </div>
                <img src={reviews} className={classes.reviewsImage} alt="App Store Reviews" />
              </Hidden>
            </div>
          </ScrollAnimation>
        </Container>
      </section>
    )
  }
}

export default withStyles(styles)(Testimonials)
