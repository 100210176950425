import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

const SlideAnimation = ({ direction = 'left', ...other }) => {
  const animationName = direction === 'left' ? 'fadeInLeft' : 'fadeInRight'
  return (
    <ScrollAnimation
      animateIn={animationName}
      animateOnce
      animatePreScroll={false}
      {...other}
    />
  )
}

export default SlideAnimation
