import React, { Component } from 'react'
import { createStyles, withStyles, Typography } from '@material-ui/core'

import StoreLinks from '../../../components/StoreLinks'

const styles = (theme) =>
  createStyles({
    comingSoon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '1.625rem',
      [theme.breakpoints.up('md')]: {
        margin: '2.1437rem 0',
      },
      [theme.breakpoints.up('xs')]: {
        margin: '1.5rem 0',
      },
    },
    text: {
      fontSize: '0.75rem',
      fontWeight: 600,
      lineHeight: '0.875rem',
      color: '#F0EDEF',
      display: 'block',
      marginRight: '0.4375rem',
    },
  })

class ComingSoon extends Component {
  render() {
    const { classes } = this.props
    return null
    return (
      <div className={classes.comingSoon}>
        <Typography className={classes.text}>Coming Soon</Typography>
        <StoreLinks />
      </div>
    )
  }
}

export default withStyles(styles)(ComingSoon)
