import React, { Component } from 'react'
import { Container } from '@material-ui/core'
import Head from 'react-helmet'

import Hero from './Hero'
import Testimonials from './Testimonials'
import YourFinances from './YourFinances'
import YourAccounts from './YourAccounts'
import Insights from './Insights'
import Features from './Features'
import Layout from '../components/Layout'
import Wistia from './Wistia'

export const isBrowser = () => typeof window !== "undefined"

export class Home extends Component {

  render() {
    return (
      <Layout>
        <Head>
          <title>Avocado Finance · Smartest Way to Manage Your Finances</title>
        </Head>
        <Container maxWidth="lg">
          <Hero />
        </Container>
        {/* <Wistia /> */}
        <Insights />
        <Features />
        <Testimonials />
      </Layout>
    )
  }
}

export default Home
