import React from 'react'
import { withStyles } from '@material-ui/core'
import { useStaticQuery, graphql } from 'gatsby'

import Section, { createSectionStyles } from './Section'

import makeProfile from './makeProfile.png'

const styles = createSectionStyles({
  // sectionMarginTop: '7.4375rem',
  bodyWidth: {
    md: '19.625rem',
    lg: '27.5rem',
  },
})

const MakeAProfile = ({ classes }) => {
  const { image, imageMd } = useStaticQuery(graphql`
    {
      image: file(name: { eq: "refinance" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMd: file(
        name: { eq: "refinance" }
        absolutePath: { regex: "/md/" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Section
      classes={classes}
      title="Make a Profile in Minutes"
      image={makeProfile}
      imageMd={makeProfile}
      alt='Two modals. First, "Your Current Loan" shows a balance of $200,000, an APR of 4.8%, a monthly payment of $1,049, a loan end date of 2045, a remaining time of 27, and a monthly savings of $266. Second, "Your New Loan" shows a balance of $200,000, an APR of 2.8%, a monthly payment of $783, a loan end date of 2050, a remaining term of 30, and a total savings of $53,295. There is an arrow between the first modal, with a Bank of America logo, and the second modal, with the Chase logo, indicating a change in banks.'
      imageDirection="left"
    >
      Your data is never shared and only used to tailor your financial insights.
    </Section>
  )
}

export default withStyles(styles)(MakeAProfile)
