import React, { Component } from 'react'
import { createStyles, withStyles, Typography } from '@material-ui/core'

import MakeAProfile from './MakeAProfile'
import ConnectYourAccounts from './ConnectYourAccounts'
import InsightsMoney from './InsightsMoney'

const styles = (theme) =>
  createStyles({
    section: {
      marginTop: '170px',
      overflowX: 'hidden',
      [theme.breakpoints.up('md')]: {
        marginTop: '12.875rem',
        marginBottom: "10rem"
      },
    },
    // header: {
    //   padding: '0 0.5625rem',
    // },
    title: {
      color: '#F0EDEF',
      fontWeight: 800,
      lineHeight: '1.32',
      marginBottom: '4.125rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '2.125rem',
        marginBottom: '45px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '2.125rem',
        marginBottom: '45px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '36px',
        marginBottom: '45px',
      },
    },
    titleBottom: {
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    note: {
      display: 'none',
      color: '#E1E1E1',
      fontSize: '2.125rem',
      lineHeight: '2.805rem',
      fontWeight: 700,
      marginTop: '6.125rem',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: '5.25rem',
      },
    },
  })

class Insights extends Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.section}>
        <div className={classes.header}>
          <Typography className={classes.title} align="center" variant="h3">
            A Fresh Way To Manage
            <span className={classes.titleBottom}> Your Finances</span>
          </Typography>
        </div>
        <MakeAProfile />
        <ConnectYourAccounts />
        <InsightsMoney />
      </div>
    )
  }
}

export default withStyles(styles)(Insights)
