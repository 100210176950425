import React, { useEffect, useState } from 'react'
import { createStyles, withStyles, Button, Typography } from '@material-ui/core'

import playIcon from './Play.svg'

import { Link } from 'gatsby'

import appStoreButton from '../../../images/availableOnApple.png'


const styles = (theme) => {
  const playIconSize = '1.6875rem'
  const flexBreakpoint = 'md'
  const flexBreakpointUp = theme.breakpoints.up(flexBreakpoint)
  return createStyles({
    outerWrapper: {
      whiteSpace: 'nowrap',
      marginBottom: 30,
    },
    innerWrapper: {
      display: 'flex',
      flexDirection: 'row',
      height: '18px',
      width: '180px',
      cursor: 'pointer',
      alignItems: 'center',
      // justifyContent: 'center',
    },
    play: {
      width: playIconSize,
      height: playIconSize,
    },
    link: {
      display: 'block',
    },
    appButton: {
      width: '150px',
    }
  })
}

const VideoSection = ({ classes }) => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener("resize", handleResize);
  }, [])

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth)
  };

  useEffect(() => {
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    script1.src = "https://fast.wistia.com/embed/medias/ueuowzzpxr.jsonp";
    script1.async = true;

    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }, [])

  return (
    <div
      class="wistia_embed wistia_async_ueuowzzpxr popover=true popoverContent=html popoverDisableAutoPlay=true"
      style={{
        marginTop: windowWidth < 760 ? 50 : 0,
        whiteSpace: 'nowrap',
        marginBottom: 30,
      }}
    >
      <div
        // id="wistia_13.thumb_container"
        className={classes.innerWrapper}
      >
        <img src={playIcon} alt="Play" className={classes.play} />
        <p style={{
          fontWeight: 'bold',
          fontSize: 14,
          lineHeight: '144%',
          fontFamily: "Montserrat",
          color: '#F0EDEF',
          marginLeft: 10,
        }}>
          See it in Action
        </p>
      </div>
    </div>
  )
}

export default withStyles(styles)(VideoSection)
