import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'

import ImageSection, { getStyles, flexBreakpoint } from './ImageSection'

const styles = (theme) => {
  const imageSectionStyles = getStyles(theme)
  const flexBreakpointUp = theme.breakpoints.up(flexBreakpoint)
  return createStyles({
    ...imageSectionStyles,
    section: {
      ...imageSectionStyles.section,
      marginTop: '7.25rem',
    },
    image: {
      ...imageSectionStyles.image,
      [flexBreakpointUp]: {
        width: '20.625rem',
      },
      [theme.breakpoints.up('lg')]: {
        width: '31.25rem',
      },
    },
    body: {
      ...imageSectionStyles.body,
      [flexBreakpointUp]: {
        ...imageSectionStyles.body[flexBreakpointUp],
        width: '23.5rem',
      },
      [theme.breakpoints.up('lg')]: {
        width: '29.875rem',
      },
    },
  })
}

const YourAccounts = ({ classes }) => {
  const { image, imageMd } = useStaticQuery(graphql`
    {
      image: file(name: { eq: "your-accounts" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMd: file(
        name: { eq: "your-accounts" }
        absolutePath: { regex: "/md/" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <ImageSection
      classes={classes}
      title="Your Accounts
      in One Place"
      image={image}
      imageMd={imageMd}
      alt="Logos of Chase, Bank of America, Citi, Wells Fargo, Fidelity, Welathfront, PNCBank, and Great Lakes. At the bottom, there is a label explaining there are 12,000 more supported banks."
      imageDirection="left"
    >
      We integrate with over 12,000 institutions to easily sync your financial
      accounts, transactions, assets, and loans. Your financial situation today
      is an indicator for the future.
    </ImageSection>
  )
}

export default withStyles(styles)(YourAccounts)
